import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/pro-light-svg-icons';


export default class Content extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div id='main'>
          <div className='width-100pct padding-left-45'>
            <a href='https://www.oomple.com'>
              <img
                src='//s3.amazonaws.com/splicegroup-publicassets/logo.png?_=202102051545'
                alt='Oomple'
                className='logo height-60'
              />
            </a>
          </div>
        </div>
        <div id='signout'>
          <a
            href='/users/sign_in'
            className='nav-item'
          >
            <FontAwesomeIcon icon={faPowerOff} fixedWidth />
            Sign In
          </a>
        </div>
      </React.Fragment>
    )
  }
}