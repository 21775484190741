import React from 'react';

export default function isResizable(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobile: (window.innerWidth <= 600),
        isTablet: (window.innerWidth > 600 && window.innerWidth <= 992),
        isDesktop: (window.innerWidth > 992)
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({
          isMobile: (window.innerWidth <= 600),
          isTablet: (window.innerWidth > 600 && window.innerWidth <= 992),
          isDesktop: (window.innerWidth > 992)
        })
      }, 50)
    }

    render() {
      const { isMobile, isTablet, isDesktop } = this.state;
      return <WrappedComponent {...this.props} {...{ isMobile, isTablet, isDesktop }} />;
    }
  }
}
