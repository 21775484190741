import React from 'react';
import PropTypes from 'prop-types';

// Mixins
import isResizable from '../../mixins/resizable';

export class SidebarNav extends React.Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    isDesktop: PropTypes.bool,
  }

  static defaultProps = {
    isDesktop: false,
  }

  render() {
    const { isDesktop, content } = this.props;

    if (!isDesktop) {
      return null;
    }

    return (
      <div className='nav-content'>
        {content}
      </div>
    )
  }
}

export default isResizable(SidebarNav);
