import React from 'react';
import { createRoot } from 'react-dom/client';
import SidebarNav from '../../../components/v4/base/sidebar_nav';
import Content from '../../../components/v4/public/sidebar_nav/content';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('nav-content');

  if(element) {
    const root = createRoot(element);
    root.render(<SidebarNav content={<Content />} />);
  }
});
